import { Inject } from '@angular/core';
import templateSource from './app.component.html';
              import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz');
import { Component, OnInit, ChangeDetectorRef, enableProdMode } from '@angular/core';
import { Router } from '@angular/router';
import { Service } from "src/libs/portal/season/service";
import $ from "jquery";

@Component({
    selector: 'app-root',
template: templateSource || '',
    styles: [``],
})
export class AppComponent implements OnInit {
    constructor(@Inject( Service)         public service: Service,@Inject( Router)         public router: Router,@Inject( ChangeDetectorRef)         public ref: ChangeDetectorRef,    ) { }

    public async ngOnInit() {
        enableProdMode();
        await this.service.init(this);
        this.service.copy = (obj) => {
            return JSON.parse(JSON.stringify(obj));
        }
        this.service.api = (api: string, data = {}, options = {}) => {
            if (options.type) options.type = options.type.toUpperCase();
            const ajax = {
                url: `/api/${api}`,
                type: "POST",
                data,
                ...options,
            };

            return new Promise((resolve) => {
                $.ajax(ajax)
                    .fail(function (data) {
                        if (data.status === 403) {
                            alert("로그인 세션이 종료되었습니다. 메인 페이지로 이동합니다.");
                            location.href = "/main";
                            return;
                        }
                        resolve({ code: data.status });
                    })
                    .always(function (res) {
                        resolve(res);
                    });
            });
        }
        this.service.error = async (message, title = "ERROR") => {
            return await this.service.alert.show({
                title,
                message,
                action: "close",
                cancel: false,
            });
        }
        this.service.success = async (message, title = "SUCCESS") => {
            return await this.service.alert.show({
                title,
                status: "success",
                message,
                action: "close",
                actionBtn: "success",
                cancel: false,
            });
        }
        this.service.statusMap = {
            publish: "bg-green-lt",
            published: "bg-green-lt",
            rejected: "bg-red-lt",
            pending: "bg-bitbucket-lt",
        };
        this.service.accessMap = {
            internal: "bg-dark-lt",
            public: "bg-teal-lt",
        };
    }
}
import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.krdc.app.bookmark');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-krdc-app-bookmark',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.krdc.app.bookmark/view.scss */
.wiz-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: contents;
  width: 100%;
  max-width: 420px;
}
.wiz-modal .modal-content {
  width: 100%;
  min-width: 280px;
  max-width: 420px;
}

.title {
  line-height: 40px;
}

table td {
  width: auto;
}

.col-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
}

.log-list {
  max-height: 600px;
}

.badge {
  line-height: 20px;
}

textarea {
  resize: none;
}`],
})
export class PortalKrdcAppBookmarkComponent implements OnInit {
    public search: any = {
        // text: "",
        page: 1,
        dump: 10
    }
    public pagination: any = {
        end: -1,
        start: 0,
    }
    public list = [];

    constructor(@Inject( Service) public service: Service) { }
    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public async load(page: number = 1) {
        this.search.page = page;
        const body = this.service.copy(this.search);
        body.resource_type = "app";
        console.log(body);
        const { code, data } = await this.service.api("resources/bookmark", body, { type: "get" });
        console.log(data);
        if (code !== 200) return await this.service.error("앱 조회 실패!");
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.list = rows;
        this.pagination.start = startpage;
        this.pagination.end = lastpage;
        await this.service.render();
    }

    public getRemoveTargets() {
        return this.list.filter(it => it.checked);
    }
    public showChecked() {
        return this.getRemoveTargets().length > 0;
    }

    public getApplyTargets() {
        return this.list.filter(it => it.checked && !this.disabled(it));
    }
    public showApply() {
        return this.getApplyTargets().length > 0;
    }

    public remove = null;

    public showRemove(item) {
        this.remove = [item];
        this.service.render();
    }

    public async removeItem() {
        const body = {
            resource_type: "app",
            resource_id: this.remove.map(it => it.id).join(","),
        };
        const { code } = await this.service.api("resources/bookmark", body, { type: "delete" });
        if (code !== 200) return await this.service.error("북마크 등록해제 실패!");
        await this.service.success("북마크 등록해제 성공!");
        this.remove = null;
        await this.load(this.search.page);
    }

    public async removeItems() {
        this.remove = this.service.copy(this.list.filter(it => it.checked));
        await this.service.render();
    }

    public disabled(item) {
        if (!item.org) return true;
        if (item.request && item.request.status === "pending") return true;
        return false;
    }

    public removeDisabled(item) {
        if (item.request && item.request.status === "accepted") return true;
        return false;
    }

}

export default PortalKrdcAppBookmarkComponent;
import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.search');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-search',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/page.search/view.scss */
.search-filter {
  width: 25%;
  max-width: 380px;
}

.fa-star {
  cursor: pointer;
}
.fa-star.fa-regular {
  color: goldenrod;
}
.fa-star.fa-solid {
  color: gold;
}

ul.nav {
  height: 40px;
}
ul.nav .nav-link {
  background-color: #f6f7fb;
}
ul.nav .nav-link.active {
  position: relative;
  background-color: transparent;
  border-bottom: 1px solid transparent;
  font-family: "main-eb";
  color: #1156aa;
}
ul.nav .nav-link.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 200px;
  height: 2px;
  background-color: #1156aa;
}

.search-area input {
  width: 280px;
}

.breadcrumb .breadcrumb-item:hover, .breadcrumb .breadcrumb-item.active {
  font-family: "main-b";
}

.apps-list .apps-list-item {
  border-bottom: 1px solid var(--wc-border);
  line-height: 1;
  padding: 8px 16px;
  display: flex;
  align-items: center;
}
.apps-list .apps-list-item.active {
  background: var(--wc-blue-light);
}
.apps-list .apps-list-item:hover {
  background: var(--wc-blue-light);
}
.apps-list .apps-list-item:hover .app-header .title {
  text-decoration: underline;
}
.apps-list .apps-list-item .avatar-area .avatar-container .avatar {
  width: 150px;
  height: 150px;
}
.apps-list .apps-list-item .title-area {
  width: calc(100% - 150px);
  height: 150px;
}
.apps-list .apps-list-item .title-area .app-header {
  font-size: 16px;
  font-weight: 1000;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
  background: transparent;
}
.apps-list .apps-list-item .title-area .app-header .tags {
  font-size: 12px;
  font-weight: normal;
}
.apps-list .apps-list-item .title-area .app-info {
  display: -webkit-box;
  line-height: 18px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #575757;
}
.apps-list .apps-list-item .app-namespace {
  font-size: 12px;
}
.apps-list .btn {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.apps-list .btn i {
  font-size: 12px;
  width: 12px;
  height: 12px;
}`],
})
export class PageSearchComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public loader = false;
    public loading = {
        show: async () => {
            this.loader = true;
            await this.service.render();
        },
        hide: async () => {
            this.loader = false;
            await this.service.render();
        },
    };

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow();
        const params = new URLSearchParams(location.search);
        this.query.text = params.get("text") || "";
        const page = params.get("page") || 1;
        this.query.page = page * 1;
        await this.search();
        await this.service.render();
    }

    public tab = "local";
    public changeTab(tab) {
        if (this.tab === tab) return;
        this.tab = tab;
        this.service.render();
        // this.search();
    }

    public toggleOrder(key) {
        const order = this.query.order;
        if (order.orderby === key) {
            if (order.order === "desc") order.order = "asc";
            else order.order = "desc";
        }
        else {
            order.orderby = key;
            order.order = "asc";
        }
        this.service.render();
        this.search();
    }

    public orders = ["name", "created_at", "updated_at"];
    public orderMap = {
        name: "제목",
        created_at: "등록일",
        updated_at: "수정일",
    };

    public groupMap = {
        preprocessing: "전처리",
        model: "모델",
        visualization: "시각화",
        etc: "기타",
    };

    public query = {
        page: 1,
        dump: 10,
        text: "",
        category: undefined,
        group: undefined,
        resource_type: undefined,
        access: undefined,
        order: {
            order: "desc",
            orderby: "updated_at",
        },
    };
    public pagination = {
        total: 0,
        start: 1,
        end: 1,
    };
    public list = [];

    public async search(page = null) {
        await this.loading.show();

        if (page === null) page = this.query.page;
        this.query.page = page;

        let body = this.service.copy(this.query);
        body = {
            ...body,
            ...body.order,
        };

        // 필터 값이 없는 경우에는 해당 속성을 제외
        if (!this.query.category) delete body.category;
        // if (this.query.category) {
        //     body.category = this.query.category.split(',');
        // }
        if (!this.query.group) delete body.group;
        if (!this.query.resource_type) delete body.resource_type;
        if (!this.query.access) delete body.access;
        console.log(body);
        const { code, data } = await this.service.api("resources", body);
        console.log(data);
        await this.loading.hide();
        if (code !== 200) {
            this.list = [];
            return await this.service.error("앱 검색에 실패하였습니다.");
        }
        const { rows, lastpage, total } = data;
        this.list = rows;
        const dump = body.dump;
        const startpage = Math.floor((page - 1) / dump) * dump + 1;
        this.pagination.start = startpage;
        this.pagination.end = lastpage;
        this.pagination.total = total;
        await this.service.render();
    }

    public onSelect = this.select.bind(this);


    public async select({ categories, groups, resourceTypes, accesses }) {
        // 필터링된 값을 query 객체에 반영
        this.query.category = categories.length > 0 ? categories : undefined;
        this.query.group = groups.length > 0 ? groups : undefined;
        this.query.resource_type = resourceTypes.length > 0 ? resourceTypes : undefined;
        this.query.access = accesses.length > 0 ? accesses : undefined;

        console.log("Applied Filters:", this.query);

        // 필터링된 조건으로 검색을 수행
        await this.search();
    }

    public displayName(user) {
        return `${user.first_name} ${user.last_name}(${user.email})`;
    }
}

export default PageSearchComponent;
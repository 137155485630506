import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.krdc.app.develop');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-krdc-app-develop',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.krdc.app.develop/view.scss */
.steps-vertical .step-item:not(:first-child) {
  margin-top: 5rem;
}
.steps-vertical .step-item:not(:last-child):after {
  height: calc(100% + 5rem);
}

.steps .step-item {
  cursor: pointer;
}

label.form-check-label {
  cursor: pointer;
}

.row + .row {
  margin-top: 8px;
}

.row .form-label,
.row .form-control {
  height: 40px;
  line-height: 40px;
}
.row .form-check {
  height: 40px;
}

.form-select + .form-select {
  margin-left: 10px;
}`],
})
export class PortalKrdcAppDevelopComponent implements OnInit {

    constructor(@Inject( Service) public service: Service) { }
    public async ngOnInit() {
        await this.service.init();
        await this.loadStorage();
        await this.loadGitlab();
        await this.setCategory(0);
    }

    public view = "config";
    public useGit = false;
    public git = {
        url: "",
        id: "",
        password: "",
    };
    public data = {
        resource_type: "app",
        name: "",
        group: "preprocessing",
        category: [null, null, null],
        command: "python main.py",
        inputs: [{ name: "", type: "string", description: "", default: "" }],
        outputs: [{ name: "", type: "string", description: "", default: "" }],
        samples: [{ path: "", description: "" }],
    };
    public categories = [[], [], []];

    public async getCategory(index) {
        const { code, data } = await this.service.api(`categories/${this.data.category[index - 1] || "ROOT"}`);
        if (code !== 200) return [];
        const category = data;
        return category.sort((a, b) => a.name - b.name);
    }
    public async setCategory(index) {
        this.categories[index] = await this.getCategory(index);
        this.data.category[2] = null;
        await this.service.render();
        if (index == 2) return;
        this.categories[2] = [];
        this.data.category[1] = null;
        await this.service.render();
    }

    public async onFileSelected(event) {
        this.service.file.resize(event.target.files[0], 150)
            .then(base64String => {
                this.data.image = base64String;
            })
    }

    public async create() {
        // if (!this.config.storage) return await this.service.error("스토리지를 생성해주세요.");
        // if (!this.config.gitlab) return await this.service.error("Gitlab 계정을 생성해주세요.");
        // const { name } = this.data;
        // this.data.name = this.data.name.replace(/\s+/g, " ").trim();
        // this.service.render();
        // if (name.replace(/\s/g, "").length === 0) return await this.service.error("앱 이름을 입력하세요.");
        // if (!/^[A-Za-z0-9_ ]{4,}$/.test(name)) return await this.service.error("앱 이름은 4글자 이상의 알파벳, 숫자, 밑줄(_), 공백으로 이루어져야 합니다.");
        let body = this.service.copy(this.data);
        body.category = body.category.findLast(value => value !== null);
        if (!body.category) return await this.service.error("카테고리를 지정해주세요.");
        body.inputs = JSON.stringify(body.inputs.filter(e => e.name));
        body.outputs = JSON.stringify(body.outputs.filter(e => e.name));
        body.samples = JSON.stringify(body.samples.filter(e => e.path));
        if (this.useGit) {
            body.git_url = this.git.url;
            body.git_id = this.git.id;
            body.git_password = this.git.password;
        }
        await this.service.loading.show();
        const { code, data } = await this.service.api("resources/create", body);
        await this.service.loading.hide();
        if (code === 403) return await this.service.error(data);
        if (code !== 200) return await this.service.error("앱 생성 요청 실패!");
        await this.service.success("앱 생성 성공!");
        this.service.href("/app/list");
    }

    public config = {
        storage: true,
        gitlab: true,
    };

    public async loadStorage() {
        const { code, data } = await this.service.api("storages/exists");
        if (code !== 200) {
            this.config.storage = false;
            return await this.service.error("스토리지 서버에 에러가 발생하였습니다. 관리자에게 문의하세요.");
        }
        this.config.storage = data;
        await this.service.render();
    }
    public async createStorage() {
        const { code } = await this.service.api("storages/create", { kind: "personal" });
        if (code !== 200) return await this.service.error("사용자 스토리지 생성에 실패하였습니다. 관리자에게 문의하세요.");
        await this.service.success("사용자 스토리지 생성에 성공하였습니다.");
        location.reload();
    }

    public async loadGitlab() {
        const { code, data } = await wiz.call("load_gitlab");
        if (code !== 200) {
            this.config.gitlab = false;
            return await this.service.error("깃랩 로드 에러가 발생하였습니다. 관리자에게 문의하세요.");
        }
        this.config.gitlab = data;
        await this.service.render();
    }
    public async createGitlab() {
        const { code, data } = await wiz.call("create_gitlab");
        if (code !== 200) return await this.service.error("사용자 계정 생성에 실패하였습니다. 관리자에게 문의하세요.");
        await this.service.success("사용자 계정 생성에 성공하였습니다. \n초기 비밀번호: " + data);
        location.reload();
    }

    public async next() {
        if (!this.config.storage) return await this.service.error("스토리지를 생성해주세요.");
        if (!this.config.gitlab) return await this.service.error("Gitlab 계정을 생성해주세요.");
        const { name } = this.data;
        this.data.name = this.data.name.replace(/\s+/g, " ").trim();
        this.service.render();
        if (name.replace(/\s/g, "").length === 0) return await this.service.error("앱 이름을 입력하세요.");
        if (!/^[A-Za-z0-9_ ]{4,}$/.test(name)) return await this.service.error("앱 이름은 4글자 이상의 알파벳, 숫자, 밑줄(_), 공백으로 이루어져야 합니다.");
        if (this.view == 'account') this.view = 'config';
        else if (this.view == 'config') this.view = 'build';
        await this.service.render();
    }

    public async previous() {
        if (this.view == 'build') this.view = 'config';
        else if (this.view == 'config') this.view = 'account';
        await this.service.render();
    }

    public async remove(type, target) {
        this.data[type].remove(target);
        await this.service.render();
    }

    public typeMap(type) {
        switch (type) {
            case "str": return "text";
            case "int": return "number";
            default: return "text";
        }
    }
}

export default PortalKrdcAppDevelopComponent;
import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.detail');
import { OnInit, DoCheck } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-detail',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/page.detail/view.scss */
.search-filter {
  width: 25%;
  max-width: 380px;
}

.fa-star {
  cursor: pointer;
}
.fa-star.fa-regular {
  color: goldenrod;
}
.fa-star.fa-solid {
  color: gold;
}

ul.nav {
  height: 40px;
}
ul.nav .nav-link {
  background-color: #f6f7fb;
}
ul.nav .nav-link.active {
  position: relative;
  background-color: transparent;
  border-bottom: 1px solid transparent;
  font-family: "main-eb";
  color: #1156aa;
}
ul.nav .nav-link.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 200px;
  height: 2px;
  background-color: #1156aa;
}

.search-area input {
  width: 280px;
}

.breadcrumb .breadcrumb-item:hover, .breadcrumb .breadcrumb-item.active {
  font-family: "main-b";
}

.apps-list .apps-list-item {
  border-bottom: 1px solid var(--wc-border);
  line-height: 1;
  padding: 8px 16px;
  display: flex;
  align-items: center;
}
.apps-list .apps-list-item.active {
  background: var(--wc-blue-light);
}
.apps-list .apps-list-item:hover {
  background: var(--wc-blue-light);
}
.apps-list .apps-list-item:hover .app-header .title {
  text-decoration: underline;
}
.apps-list .apps-list-item .avatar-area .avatar-container .avatar {
  width: 150px;
  height: 150px;
}
.apps-list .apps-list-item .title-area {
  width: calc(100% - 150px);
  height: 150px;
}
.apps-list .apps-list-item .title-area .app-header {
  font-size: 16px;
  font-weight: 1000;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
  background: transparent;
}
.apps-list .apps-list-item .title-area .app-header .tags {
  font-size: 12px;
  font-weight: normal;
}
.apps-list .apps-list-item .title-area .app-info {
  display: -webkit-box;
  line-height: 18px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #575757;
}
.apps-list .apps-list-item .app-namespace {
  font-size: 12px;
}
.apps-list .btn {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.apps-list .btn i {
  font-size: 12px;
  width: 12px;
  height: 12px;
}`],
})
export class PageDetailComponent implements OnInit, DoCheck {
    public id = "";
    public item;

    constructor(@Inject( Service) public service: Service) {
        if (!WizRoute.segment.id)
            return service.href("/search");
        this.id = WizRoute.segment.id;
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow();
        await this.load();
    }

    public async ngDoCheck() {
        if (!WizRoute.segment.id)
            return this.service.href("/search");
        this.id = WizRoute.segment.id;
    }

    public async load() {
        const body = this.service.copy({ resource_id: this.id });
        const { code, data } = await this.service.api("resources/load", body);
        console.log(data)
        this.item = data;
        await this.service.render();

    }
    public groupMap = {
        preprocessing: "전처리",
        model: "모델",
        visualization: "시각화",
        etc: "기타",
    };
}

export default PageDetailComponent;
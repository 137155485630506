import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.krdc.admin.template');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-krdc-admin-template',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.krdc.admin.template/view.scss */
/* src/app/portal.krdc.admin.template/view.scss: no such file or directory */`],
})
export class PortalKrdcAdminTemplateComponent implements OnInit {
    public search: any = {
        text: "",
        page: 1,
        dump: 10
    }
    public pagenation: any = {
        end: -1,
        start: 0,
    }
    public apps = [
        { id: "template ID", name: "템플릿 명칭", created_at: new Date().format("yyyy-MM-dd HH:mm:ss"), description: "template description", type: null }
    ];
    public checked = [];

    constructor(@Inject( Service) public service: Service) { }
    public async ngOnInit() {
        await this.service.init();
    }

    public async load() {

    }
}

export default PortalKrdcAdminTemplateComponent;
import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.krdc.org.develop');
import { OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Service } from "src/libs/portal/season/service";
import { ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'wiz-portal-krdc-org-develop',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.krdc.org.develop/view.scss */
.row + .row {
  margin-top: 8px;
}

.row .form-label,
.row .form-control {
  height: 40px;
  line-height: 40px;
}
.row .form-check,
.row .form-select {
  height: 40px;
}
.row textarea.form-control {
  min-height: 150px;
  height: auto;
  line-height: 40px;
}`],
})
export class PortalKrdcOrgDevelopComponent implements OnInit {

    constructor(@Inject( Service)         public service: Service,@Inject( Location)         private location: Location,@Inject( ChangeDetectorRef    )         private cdr: ChangeDetectorRef    ) { }

    public async ngOnInit() {
        await this.service.init();
    }
    public data = {
        resource_type: "org",
        name: "",
        org_name: "",
        org_url: "",
        org_datae: "",
    };

    public async onFileSelected(event) {
        this.service.file.resize(event.target.files[0], 150, 1.0)
            .then(base64String => {
                this.data.image = base64String;
                this.cdr.detectChanges();
            })
    }

    public async create() {

        let body = this.service.copy(this.data);
        if (body.name.replace(/\s/g, "").length === 0) return await this.service.error("프레임워크 이름을 입력하세요.");
        if (!/^[A-Za-z가-힣0-9_ ]{4,}$/.test(body.name)) return await this.service.error("프레임워크 이름은 4글자 이상의 알파벳, 숫자, 밑줄(_), 공백으로 이루어져야 합니다.");
        if (!/^\d{4}-\d{2}-\d{2}$/.test(body.org_date)) {
            return await this.service.error("운영기관 날짜는 'xxxx-xx-xx' 형식이어야 합니다.");
        }
        await this.service.loading.show();
        const { code, data } = await this.service.api('resources/create', body);
        await this.service.loading.hide();

        if (code === 403) return await this.service.error(data);
        if (code !== 200) return await this.service.error("운영기관 생성 요청 실패!");
        await this.service.success("운영기관 생성 성공!");
        this.service.href("/org/list");
    }


}

export default PortalKrdcOrgDevelopComponent;
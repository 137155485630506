import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.krdc.admin.regist');
import { OnInit, Input } from '@angular/core';

@Component({
    selector: 'wiz-portal-krdc-admin-regist',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.krdc.admin.regist/view.scss */
/* src/app/portal.krdc.admin.regist/view.scss: no such file or directory */`],
})
export class PortalKrdcAdminRegistComponent implements OnInit {
    @Input() title: any;

    public async ngOnInit() {
    }
}

export default PortalKrdcAdminRegistComponent;
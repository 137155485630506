import '@angular/compiler';
import { PortalKrdcAdminApproveComponent } from './portal.krdc.admin.approve/portal.krdc.admin.approve.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PageSearchComponent } from './page.search/page.search.component';
import { PortalKrdcAdminUserComponent } from './portal.krdc.admin.user/portal.krdc.admin.user.component';
import { PageMainComponent } from './page.main/page.main.component';
import { PortalKrdcAdminRegistComponent } from './portal.krdc.admin.regist/portal.krdc.admin.regist.component';
import { ComponentAsideComponent } from './component.aside/component.aside.component';
import { ComponentLoadingComponent } from './component.loading/component.loading.component';
import { PortalKrdcAdminPublishComponent } from './portal.krdc.admin.publish/portal.krdc.admin.publish.component';
import { PortalKrdcWorkflowUsageComponent } from './portal.krdc.workflow.usage/portal.krdc.workflow.usage.component';
import { PortalKrdcWorkflowBookmarkComponent } from './portal.krdc.workflow.bookmark/portal.krdc.workflow.bookmark.component';
import { ComponentDatePickerComponent } from './component.date.picker/component.date.picker.component';
import { PageDetailComponent } from './page.detail/page.detail.component';
import { PortalKrdcSearchFilterComponent } from './portal.krdc.search.filter/portal.krdc.search.filter.component';
import { PortalKrdcOrgListComponent } from './portal.krdc.org.list/portal.krdc.org.list.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { PortalKrdcAppUsageComponent } from './portal.krdc.app.usage/portal.krdc.app.usage.component';
import { PortalKrdcOrgDevelopComponent } from './portal.krdc.org.develop/portal.krdc.org.develop.component';
import { LayoutUserComponent } from './layout.user/layout.user.component';
import { PortalKrdcAdminListComponent } from './portal.krdc.admin.list/portal.krdc.admin.list.component';
import { PortalSeasonLoadingComponent } from './portal.season.loading/portal.season.loading.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PageMypageComponent } from './page.mypage/page.mypage.component';
import { PageIntroComponent } from './page.intro/page.intro.component';
import { ComponentStepComponent } from './component.step/component.step.component';
import { PortalKrdcAdminTotalComponent } from './portal.krdc.admin.total/portal.krdc.admin.total.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { LayoutAdminComponent } from './layout.admin/layout.admin.component';
import { ComponentFooterComponent } from './component.footer/component.footer.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { PortalKrdcAppBookmarkComponent } from './portal.krdc.app.bookmark/portal.krdc.app.bookmark.component';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { ComponentQuestionComponent } from './component.question/component.question.component';
import { ComponentNavUserComponent } from './component.nav.user/component.nav.user.component';
import { PortalKrdcAppDevelopComponent } from './portal.krdc.app.develop/portal.krdc.app.develop.component';
import { PageOrgComponent } from './page.org/page.org.component';
import { PortalKrdcAdminTemplateComponent } from './portal.krdc.admin.template/portal.krdc.admin.template.component';
import { PortalKrdcAppListComponent } from './portal.krdc.app.list/portal.krdc.app.list.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NuMonacoEditorModule } from '@ng-util/monaco-editor';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';

@NgModule({
    declarations: [
        AppComponent,
        PortalKrdcAdminApproveComponent,
        PortalSeasonLoadingHexaComponent,
        PageSearchComponent,
        PortalKrdcAdminUserComponent,
        PageMainComponent,
        PortalKrdcAdminRegistComponent,
        ComponentAsideComponent,
        ComponentLoadingComponent,
        PortalKrdcAdminPublishComponent,
        PortalKrdcWorkflowUsageComponent,
        PortalKrdcWorkflowBookmarkComponent,
        ComponentDatePickerComponent,
        PageDetailComponent,
        PortalKrdcSearchFilterComponent,
        PortalKrdcOrgListComponent,
        PortalSeasonViewerTreeComponent,
        PortalSeasonTabComponent,
        PortalKrdcAppUsageComponent,
        PortalKrdcOrgDevelopComponent,
        LayoutUserComponent,
        PortalKrdcAdminListComponent,
        PortalSeasonLoadingComponent,
        PortalSeasonStatusbarComponent,
        PortalSeasonAlertComponent,
        PageMypageComponent,
        PageIntroComponent,
        ComponentStepComponent,
        PortalKrdcAdminTotalComponent,
        PortalSeasonLoadingSeasonComponent,
        LayoutAdminComponent,
        ComponentFooterComponent,
        PortalSeasonUiDropdownComponent,
        PortalKrdcAppBookmarkComponent,
        PortalSeasonPagenationComponent,
        LayoutEmptyComponent,
        ComponentQuestionComponent,
        ComponentNavUserComponent,
        PortalKrdcAppDevelopComponent,
        PageOrgComponent,
        PortalKrdcAdminTemplateComponent,
        PortalKrdcAppListComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgbModule,
        KeyboardShortcutsModule.forRoot(),
        NuMonacoEditorModule.forRoot({ baseUrl: `lib` }),
        
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'ko-KR'},
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
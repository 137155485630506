import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.krdc.admin.total');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-krdc-admin-total',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.krdc.admin.total/view.scss */
/* src/app/portal.krdc.admin.total/view.scss: no such file or directory */`],
})
export class PortalKrdcAdminTotalComponent implements OnInit {

    @Input() title: any;

    public info = {
        apps: 0
    };

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow();
        await this.load();
    }

    public async load() {
        const { code, data } = await wiz.call("count");
        this.info.apps = data.apps;
        this.info.users = data.users;
        await this.service.render();
    }

}

export default PortalKrdcAdminTotalComponent;
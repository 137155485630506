import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.footer');
import { OnInit, Input } from '@angular/core';

@Component({
    selector: 'wiz-component-footer',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/component.footer/view.scss */
@charset "UTF-8";
.footer {
  text-align: right;
  font-family: "NanumSquareRg";
  height: 35px;
  background-color: #142E53;
  color: white;
  font-size: 12px;
  display: flex;
  /* Flex 컨테이너로 설정 */
  align-items: center;
  /* 수직 가운데 정렬 */
  justify-content: flex-end;
  /* 우측 끝으로 배치 */
  bottom: 0;
  position: absolute;
  width: 100%;
}`],
})
export class ComponentFooterComponent implements OnInit {
    @Input() title: any;

    public async ngOnInit() {
    }
}

export default ComponentFooterComponent;
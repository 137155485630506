import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.krdc.workflow.usage');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-krdc-workflow-usage',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.krdc.workflow.usage/view.scss */
/* src/app/portal.krdc.workflow.usage/view.scss: no such file or directory */`],
})
export class PortalKrdcWorkflowUsageComponent implements OnInit {
    public search: any = {
        text: "",
        page: 1,
        dump: 10
    }
    public pagenation: any = {
        end: -1,
        start: 0,
    }
    public apps = [
        { name: "앱 이름", interest: 0, apply: 0, approve: 0, use: 0 }
    ];
    public checked = [];

    constructor(@Inject( Service) public service: Service) { }
    public async ngOnInit() {
        await this.service.init();
    }

    public async load() {

    }
}

export default PortalKrdcWorkflowUsageComponent;
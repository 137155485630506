import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.loading');
import { OnInit } from '@angular/core';

@Component({
    selector: 'wiz-component-loading',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/component.loading/view.scss */
.loading-zone {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.08);
}
.loading-zone .loading-zone-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.season-loading-default {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.season-loading-default svg path,
.season-loading-default svg rect {
  fill: #1156aa;
}`],
})
export class ComponentLoadingComponent implements OnInit {

    public async ngOnInit() {}
}

export default ComponentLoadingComponent;
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.intro');
import { OnInit, Input } from '@angular/core';

@Component({
    selector: 'wiz-page-intro',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/page.intro/view.scss */
/* src/app/page.intro/view.scss: no such file or directory */`],
})
export class PageIntroComponent implements OnInit {
    @Input() title: any;

    public async ngOnInit() {
    }
}

export default PageIntroComponent;
import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.main');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-main',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/page.main/view.scss */
@charset "UTF-8";
.input-group-hub {
  max-width: 900px;
  width: auto;
  margin: 0 auto;
}

.krdc-hub {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.krdc-hub .container h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}
.krdc-hub .container p {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.main-hub-img {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
}
.main-hub-img img {
  align-self: flex-start;
  width: 100%;
  max-width: 250px;
}
.main-hub-img .main-hub-img-text {
  margin-top: 100px;
}
.main-hub-img h2 {
  color: rgb(96, 94, 94);
  font-size: 1.5rem;
  margin-bottom: 10px;
  /* 버튼과의 간격 */
}
.main-hub-img button {
  background-color: var(--wc-blue);
  align-self: flex-end;
  margin-top: 10px;
}`],
})
export class PageMainComponent implements OnInit {
    public info = {
        apps: 0,
        workflow: 0,
        service: 0,
    };
    public text = "";

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow();
        await this.load();
    }

    public async load() {
        const { code, data } = await wiz.call("count");
        this.info.apps = data.apps;
        this.info.workflow = data.workflows;
        await this.service.render();
    }

    public search() {
        this.service.app.router.navigate(['/search'], { queryParams: { text: this.text } });
    }

    public async openNotebook() {
        const { code, data } = this.service.api("notebook/login");
    }
}

export default PageMainComponent;
import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('layout.user');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-layout-user',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/layout.user/view.scss */
body {
  width: 100%;
}

.wiz-page {
  width: 100%;
  margin: 0;
  z-index: 2;
  flex-direction: column;
}

.wiz-nav {
  width: 75vw;
  margin: 0px auto;
}
@media (max-width: 768px) {
  .wiz-nav {
    width: 92vw;
  }
}

.wiz-view {
  width: 75vw;
  margin: 0px auto;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}
@media (max-width: 768px) {
  .wiz-view {
    width: 92vw;
  }
}

.wiz-view::-webkit-scrollbar {
  display: none;
}

.season-page {
  width: 90vw;
  margin: 0px auto;
}
@media (max-width: 768px) {
  .season-page {
    width: 92vw;
  }
}

.season-page::-webkit-scrollbar {
  display: none;
}

.loading-zone {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999;
}
.loading-zone .loading-zone-container {
  width: 100%;
  height: 100%;
  position: relative;
}`],
})
export class LayoutUserComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public loaded: boolean = false;

    public async ngOnInit() {
        await this.service.init();
        this.loaded = true;
        await this.service.render();
    }
}

export default LayoutUserComponent;
import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.aside');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { HostListener } from '@angular/core';

@Component({
    selector: 'wiz-component-aside',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/component.aside/view.scss */
.navbar-vertical {
  overflow: auto;
  position: relative;
  height: 100%;
  box-shadow: none;
  min-width: 260px;
  border-right: 1px solid var(--wc-border);
}
@media (max-width: 768px) {
  .navbar-vertical {
    border-bottom: 1px solid var(--wc-border);
    border-right: none;
  }
}

li.nav-item.active .nav-link {
  color: #6C8DF6 !important;
  font-weight: 1000;
}
li.nav-item.active i {
  color: #6C8DF6;
}
li.nav-item.active::after {
  border-color: #6C8DF6;
}

.navbar-brand-image {
  width: 160px;
  height: auto;
  margin-top: 16px;
  margin-bottom: 12px;
}
@media (max-width: 768px) {
  .navbar-brand-image {
    width: auto;
    height: 32px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.user-profile {
  padding: 28px;
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
}
@media (max-width: 768px) {
  .user-profile {
    padding: 24px;
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
  }
}
.user-profile .card {
  box-shadow: none;
  border-right: none;
  border-left: none;
  border-radius: 0;
}
.user-profile .card .card-body {
  border-top: none !important;
}
.user-profile .card .card-footer {
  padding: 0;
  background-color: var(--wc-background);
  border-radius: 0;
}
.user-profile .clicks {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.user-profile .clicks a {
  display: block;
  width: 100%;
  color: #63667b;
  text-align: center;
}
.user-profile .clicks .click {
  cursor: pointer;
  padding: 0.75rem;
  display: inline-block;
}
.user-profile .clicks .click:hover {
  color: var(--wc-blue);
  background-color: var(--wc-blue-light);
}
.user-profile .clicks .line {
  margin: 0.75rem 0;
  border-right: 1px solid #63667b;
}
.user-profile .userinfo {
  padding: 0.25rem;
  text-align: left;
}
.user-profile .userinfo .name {
  font-family: "main-b";
  color: #2D333F;
  font-size: 20px;
}
.user-profile .userinfo .uclass,
.user-profile .userinfo .mail {
  font-size: 12px;
  color: #9097AD;
}

a {
  cursor: pointer;
}`],
})
export class ComponentAsideComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
    }

    @HostListener('document:click')
    public clickout() {
        this.service.navbar.toggle(true);
    }

    public isActive(link: string) {
        return location.pathname.indexOf(link) === 0
    }

}

export default ComponentAsideComponent;
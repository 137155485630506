import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.krdc.org.list');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { AnsiUp } from 'ansi_up';


@Component({
    selector: 'wiz-portal-krdc-org-list',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.krdc.org.list/view.scss */
table td {
  width: auto;
}`],
})
export class PortalKrdcOrgListComponent implements OnInit {
    public ansiUp = new AnsiUp();
    public search: any = {
        text: "",
        page: 1,
        dump: 10
    }
    public pagination: any = {
        end: -1,
        start: 0,
    }

    public orgs = [];

    public data = {
        resource_type: "org",
        org_name: "",
        name: "",
        org_url: "",
        org_date: "",
    };

    constructor(@Inject( Service) public service: Service) { }
    public async ngOnInit() {
        await this.service.init();
        await this.load();
        this.ansiUp.use_classes = true;
    }

    public async load(page: number = 1) {
        this.search.page = page;
        const { code, data } = await wiz.call("load", this.search);
        if (code !== 200) return await this.service.error("운영기관 조회 실패!");
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.orgs = rows;
        console.log(this.orgs);
        this.pagination.start = startpage;
        this.pagination.end = lastpage;
        await this.service.render();
    }

    public showChecked() {
        return this.orgs.filter(it => it.checked).length > 0;
    }
}

export default PortalKrdcOrgListComponent;
import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.krdc.admin.user');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-krdc-admin-user',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/portal.krdc.admin.user/view.scss */
.wiz-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: contents;
  width: 100%;
  max-width: 420px;
}
.wiz-modal .modal-content {
  width: 100%;
  min-width: 280px;
  max-width: 420px;
}`],
})
export class PortalKrdcAdminUserComponent implements OnInit {
    public search = {
        text: "",
        page: 1,
    }
    public pagenation = {
        end: -1,
        start: 0,
    }
    public list = [];
    public groups = [];

    constructor(@Inject( Service) public service: Service) { }
    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow("admin", "/main");
        await this.load();
    }

    public async load(page = 1) {
        this.search.page = page;
        await this.service.loading.show();
        const body = this.service.copy(this.search);
        const { code, data } = await wiz.call("load", body);
        if (code !== 200) {
            await this.service.loading.hide();
            return await this.service.error("사용자 목록 불러오기 실패!");
        }
        const { rows, lastpage, groups } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.list = rows;
        this.groups = this.sortGroups(groups);
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        await this.service.loading.hide();
    }

    public sortGroups(groups) {
        const res = [];
        const i1 = groups.findIndex(it => it.name === "일반사용자");
        if (i1 >= 0) {
            res.push(groups[i1]);
            groups.splice(i1, 1);
        }
        const i2 = groups.findIndex(it => it.name === "앱 제공자");
        if (i2 >= 0) {
            res.push(groups[i2]);
            groups.splice(i2, 1);
        }
        const i3 = groups.findIndex(it => it.name === "클러스터 관리자");
        if (i3 >= 0) {
            res.push(groups[i3]);
            groups.splice(i3, 1);
        }
        res.push(...groups);
        return res;
    }

    public ts2str(ts) {
        return new Date(+ts).format("yyyy.MM.dd HH:mm:ss");
    }

    public modal = {
        mode: null,
        data: null,
        current: null,
    };
    public showModal(mode, data = null, current = null) {
        this.modal.current = current;
        this.modal.mode = mode;
        this.modal.data = data;
        this.service.render();
    }

    public async changeRole() {
        const user = this.modal.data;
        const _from = user.role;
        const _to = this.groups.find(it => it.id === this.modal.current);
        if (_from.id === _to.id) return;
        const res = await this.service.alert.show({
            title: "사용자 권한 변경",
            message: `${user.email} 사용자의 권한을 변경하시겠습니까?\n${_from.name} => ${_to.name}`,
            action: "변경하기",
            status: "error",
            actionBtn: "error",
            cancel: "취소",
        });
        if (!res) return;

        await this.service.loading.show();
        const body = {
            user_id: user.id,
            from: _from.id,
            to: _to.id,
        };
        const { code } = await wiz.call("change_role", body);
        await this.service.loading.hide();
        if (code !== 200) return await this.service.error("Error!");
        await this.service.success("사용자 권한 변경 성공!");
        this.showModal(null);
        await this.load(this.search.page);
    }

    public async disableUser(user) {
        const res = await this.service.alert.show({
            title: "사용자 비활성화",
            message: `${user.email} 사용자를 비활성화하시겠습니까?`,
            action: "비활성화",
            status: "error",
            actionBtn: "error",
            cancel: "취소",
        });
        if (!res) return;

        const body = {
            user_id: user.id,
            action: "disable",
        };
        await this.service.loading.show();
        const { code } = await wiz.call("toggle_user", body);
        await this.service.loading.hide();
        if (code !== 200) return await this.service.error("Error!");
        await this.service.success("사용자 비활성화 성공!");
        await this.load(this.search.page);
    }

    public async enableUser(user) {
        const res = await this.service.alert.show({
            title: "사용자 활성화",
            message: `${user.email} 사용자를 다시 활성화하시겠습니까?`,
            action: "활성화",
            status: "success",
            actionBtn: "success",
            cancel: "취소",
        });
        if (!res) return;

        const body = {
            user_id: user.id,
            action: "enable",
        };
        await this.service.loading.show();
        const { code } = await wiz.call("toggle_user", body);
        await this.service.loading.hide();
        if (code !== 200) return await this.service.error("Error!");
        await this.service.success("사용자 활성화 성공!");
        await this.load(this.search.page);
    }
}

export default PortalKrdcAdminUserComponent;
import $ from "jquery";

export default class Request {
    constructor() { }

    public async post(url: string, data: any = {}) {
        let request = () => {
            return new Promise((resolve) => {
                $.ajax({
                    url: url,
                    type: "POST",
                    data: data
                })
                .fail(function (data) {
                    if (data.status === 403) {
                        alert("로그인 세션이 종료되었습니다. 메인 페이지로 이동합니다.");
                        location.href = "/main";
                        return;
                    }
                    resolve({ code: data.status });
                })
                .always(function (res) {
                    resolve(res);
                });
            });
        }

        return await request();
    }

}